import React from "react"
import { getBackgroundImage } from "../../plugins/common"
import * as style from "./Header.module.scss"

const Header = ({ header }) => {
  const { backgroundImage, title } = header
  const bg = getBackgroundImage(backgroundImage)

  return (
    <div
      className={`${style.header} d-grid`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <h1 className={`text-center ming`}>{title}</h1>
    </div>
  )
}

export default Header
