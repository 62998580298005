// extracted by mini-css-extract-plugin
export var alertRed = "QuotationForm-module--alert-red--WJRkv";
export var benefit = "QuotationForm-module--benefit--lsMdR";
export var benefitIcon = "QuotationForm-module--benefitIcon--kgVOE";
export var benefits = "QuotationForm-module--benefits--n5FDI";
export var bgBarGrey = "QuotationForm-module--bg-bar-grey--qt-O2";
export var bgBeige = "QuotationForm-module--bg-beige--8pAP4";
export var bgCopper = "QuotationForm-module--bg-copper--Nv2y0";
export var bgDaffodil = "QuotationForm-module--bg-daffodil--2EBUN";
export var bgGreyText = "QuotationForm-module--bg-grey-text--iEzEH";
export var bgMing = "QuotationForm-module--bg-ming--J7whl";
export var bgMint = "QuotationForm-module--bg-mint--PWYFz";
export var bgRed = "QuotationForm-module--bg-red--wT6Wa";
export var bgRedTrans = "QuotationForm-module--bg-red-trans--NwKq9";
export var bgSand = "QuotationForm-module--bg-sand--vhcmZ";
export var bgSoftSun = "QuotationForm-module--bg-softSun--8Kfuy";
export var bgTeal = "QuotationForm-module--bg-teal--V6R0b";
export var bgWhite = "QuotationForm-module--bg-white--Sw3eD";
export var bgWomenSharp = "QuotationForm-module--bg-women-sharp--2tXsl";
export var blackText = "QuotationForm-module--black-text--MSaVU";
export var checkbox = "QuotationForm-module--checkbox--GuATr";
export var copper = "QuotationForm-module--copper--SS0RI";
export var corners = "QuotationForm-module--corners--QgqCD";
export var cursorNormal = "QuotationForm-module--cursor-normal--bLiZc";
export var cursorPointer = "QuotationForm-module--cursor-pointer--ZK0W6";
export var dUnset = "QuotationForm-module--d-unset--4XQ39";
export var darkGrey = "QuotationForm-module--dark-grey--fRUQZ";
export var dash = "QuotationForm-module--dash--mYyje";
export var divider = "QuotationForm-module--divider--H8BN0";
export var errorField = "QuotationForm-module--errorField--anoDJ";
export var flex = "QuotationForm-module--flex--Hnz+r";
export var flex1 = "QuotationForm-module--flex-1--D8TBA";
export var fontSize12 = "QuotationForm-module--font-size-12--VARe1";
export var fontSize20 = "QuotationForm-module--font-size-20--aNIXv";
export var form = "QuotationForm-module--form--zZ4sm";
export var formButton = "QuotationForm-module--formButton--09C7E";
export var greyPlaceholder = "QuotationForm-module--grey-placeholder--Iwsaf";
export var greyText = "QuotationForm-module--grey-text--XI+44";
export var h1sizing = "QuotationForm-module--h1sizing--o46r3";
export var h2sizing = "QuotationForm-module--h2sizing--U03bQ";
export var hidden = "QuotationForm-module--hidden--qmnWi";
export var imageAni = "QuotationForm-module--imageAni--9TVAU";
export var imageZoom = "QuotationForm-module--imageZoom--y+SNv";
export var imageZoomGatsby = "QuotationForm-module--imageZoomGatsby--yMmFv";
export var italic = "QuotationForm-module--italic--s16FS";
export var label = "QuotationForm-module--label--5d8+K";
export var lightGrey = "QuotationForm-module--light-grey--m8cuF";
export var logo = "QuotationForm-module--logo--GUgve";
export var lora = "QuotationForm-module--lora--Dfitd";
export var loraBold = "QuotationForm-module--lora-Bold--vAwp5";
export var loraBoldItalic = "QuotationForm-module--lora-BoldItalic--elwFh";
export var loraMedium = "QuotationForm-module--lora-Medium--+4c1m";
export var loraSemiBold = "QuotationForm-module--lora-SemiBold--CUeZO";
export var main = "QuotationForm-module--main--iewnQ";
export var ming = "QuotationForm-module--ming--8QaSx";
export var mingHover2 = "QuotationForm-module--ming-hover-2--PWysl";
export var modalOpen = "QuotationForm-module--modal-open--XOEos";
export var noUnderline = "QuotationForm-module--no-underline--6ZSWI";
export var openSans = "QuotationForm-module--openSans--Uz7CM";
export var openSansBold = "QuotationForm-module--openSans-Bold--wyN6A";
export var openSansSemiBold = "QuotationForm-module--openSans-SemiBold--MI85T";
export var quotation = "QuotationForm-module--quotation--h5bew";
export var round = "QuotationForm-module--round--zHfh-";
export var roundCorners = "QuotationForm-module--round-corners--pQNv1";
export var selected = "QuotationForm-module--selected--gHo3m";
export var softCorners = "QuotationForm-module--soft-corners--+PwRE";
export var softShadows = "QuotationForm-module--soft-shadows--r2ZxH";
export var softerCorners = "QuotationForm-module--softer-corners--WxwBv";
export var softyCorners = "QuotationForm-module--softy-corners--qjiJm";
export var tableShadow = "QuotationForm-module--table-shadow--ZlCfR";
export var teal = "QuotationForm-module--teal--7-mA0";
export var topNavShadow = "QuotationForm-module--top-nav-shadow--ZZNi9";
export var uppercase = "QuotationForm-module--uppercase--CJ4w7";
export var wFull = "QuotationForm-module--w-full--tnwk6";
export var white = "QuotationForm-module--white--nY6Xp";
export var womenRed = "QuotationForm-module--women-red--YnqOb";
export var womenSharp = "QuotationForm-module--women-sharp--WIsOk";