import React, { useState, useEffect } from "react"
import Icon from "@mdi/react"
import { mdiCheckBold } from "@mdi/js"
import {
  formValidation,
  gaEventPush,
  getFullPhoneCodes,
} from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import * as style from "./QuotationForm.module.scss"
import { createPipedrivePersonLead } from "../../DAO/eqtDAO"
import axios from "../../plugins/axios"
import { preferredLang } from "../../enumerator/preferredLang"
import DropdownV2 from "../quotation/DropdownV2"
import Button from "../Button"
import Loading from "../Loading"
// import { pipedriveValue } from "../../enumerator/pipedriveValue"

const QuotationForm = ({ formData, lang, token, location }) => {
  // console.log(formData)
  const {
    introduction,
    yourNeedsTitle,
    aboutYouTitle,
    emailLabel,
    insuranceForLabel,
    insuranceTypeLabel,
    nameLabel,
    phoneLabel,
    whatToCoverLabel,
    forMyselfLabel,
    forTeamLabel,
    insuranceTypes,
    form_benefits,
    thanksMsg,
    invalidFormMsg,
    submitLabel,
    contactNameLabel,
    companyNameLabel,
  } = formData

  const benefitList = form_benefits
    .sort((a, b) => a.weighting - b.weighting)
    .map(b => {
      return {
        name: b.name,
        value: b.crmId,
        icon: b.icon,
        selected: b.crmId === 40 ? true : false,
      }
    })

  // preselectType:
  // 1. team
  // 2. health  647
  // 3. life  619
  // 4. critical  648
  // 5. unknown  649
  let preselectType = null
  const params = new URLSearchParams(location.search)
  if (params) preselectType = params.get("type")

  const [forTeam, setForTeam] = useState(false)
  const [benefits, setBenefits] = useState(benefitList)

  const updateBenefits = value => {
    // console.log("value = ", value)
    const list = benefits.map(b => {
      if (b.value === value) b.selected = !b.selected
      return b
    })
    // console.log("list = ", list)
    setBenefits(list)
  }

  const defaultFormError = {
    name: false,
    phone: false,
    email: false,
    companyName: false,
  }
  const phoneCodeList = getFullPhoneCodes()
  const [quoteCompanyName, setQuoteCompanyName] = useState("")
  const [quoteName, setQuoteName] = useState("")
  const [quoteEmail, setQuoteEmail] = useState("")
  const [quotePhone, setQuotePhone] = useState("")
  const [quoteCountryCode, setQuoteCountryCode] = useState("+852")
  const [quoteType, setQuoteType] = useState(insuranceTypes[0])
  const [formError, setFormError] = useState(defaultFormError)
  const [errorMessage, setErrorMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const gaEventLabels = {
    647: "quote_individual_health_submit",
    648: "quote_individual_ci_submit",
    619: "quote_individual_life_submit",
    649: "quote_individual_dont_know",
    608: "quote_team_submit",
  }

  const submitQuote = async () => {
    const formData = {
      name: quoteName,
      companyName: quoteCompanyName,
      email: quoteEmail,
      phone: quotePhone,
    }
    if (
      !formValidation(
        formData,
        setFormError,
        setErrorMessage,
        invalidFormMsg,
        forTeam
      )
    ) {
      return
    }

    setLoading(true)
    try {
      let pipedriveEntry = {
        name: quoteName,
        email: quoteEmail,
        phone: quoteCountryCode + quotePhone,
        source: forTeam ? 608 : parseInt(quoteType.value),
        // url: location.href,
        // source: pipedriveValue.SOURCE_ALEA,
        // product: forTeam
        //   ? pipedriveValue.PRODUCT_TEAM
        //   : parseInt(quoteType.value),
        // formPosition: pipedriveValue.POSITION_CONTENT,
        preferedLanguage: preferredLang[lang],
        // benefitIds:
        //   !forTeam && parseInt(quoteType.value) !== 647
        //     ? null
        //     : benefits.filter(b => b.selected).map(b => parseInt(b.value)),
      }
      if (forTeam) {
        pipedriveEntry.companyName = quoteCompanyName
        pipedriveEntry.isOrg = 70
      }
      const pipedriveRes = await createPipedrivePersonLead(
        token,
        pipedriveEntry
      )
      // console.log("pipedriveRes = ", pipedriveRes)

      // //log
      const log = {
        title: quoteName + " from get-a-quote",
        lang: lang,
        raw: pipedriveEntry,
      }
      await axios
        .strapi(token)
        .post("/get-a-quote-submissions", log)
        .catch(err => {
          throw err
        })

      gaEventPush(
        window,
        "event",
        "form submission",
        "lead",
        forTeam ? gaEventLabels[608] : gaEventLabels[quoteType.value]
      )
      // if (typeof window !== "undefined" && window.dataLayer) {
      //   window.dataLayer.push({
      //     event: {
      //       hitType: "event",
      //       eventCategory: "form submission",
      //       eventAction: "lead",
      //       eventLabel: forTeam?gaEventLabels[608]:gaEventLabels[quoteType.value],
      //     },
      //   })
      // }

      setSubmitted(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const isForTeam = isTeamBtn => {
    if ((!forTeam && !isTeamBtn) || (forTeam && isTeamBtn))
      return style.selected
    return ""
  }
  const isTypeSelected = value => {
    if (quoteType.value === value) return style.selected
    return ""
  }

  useEffect(() => {
    if (preselectType === "team") setForTeam(true)
    else if (preselectType) {
      const typeValueSet = {
        health: 647,
        life: 619,
        critical: 648,
        unknown: 649,
      }
      const defaultQuoteType = typeValueSet[preselectType]
        ? insuranceTypes.find(
            type => parseInt(type.value) === typeValueSet[preselectType]
          )
        : insuranceTypes[0]

      setQuoteType(defaultQuoteType)
    }
  }, [preselectType])

  return (
    <div className={`${style.form} soft-corners border border-dark`}>
      {loading ? (
        <div className={`d-relative`}>
          <Loading />
        </div>
      ) : (
        ""
      )}
      <div className={`ming mb-4`}>
        <CustomMarkdown html={introduction} />
      </div>
      {!submitted ? (
        <div className={`flex flex-lg-row flex-column`}>
          <div className={`col-lg-6`}>
            <div className={`mb-5 col-lg-12`}>
              <h2 className={`ming`}>{yourNeedsTitle}</h2>
            </div>
            <div className={`mb-5`}>
              <div className={`mb-5`}>
                <div className={`mb-3 ming openSans-SemiBold`}>
                  {insuranceForLabel}
                </div>
                <div className={`flex gap-2`}>
                  <div
                    role="button"
                    className={`${style.formButton} ${isForTeam(
                      false
                    )} corners py-1 text-center col-6 col-lg-5`}
                    onClick={() => setForTeam(false)}
                    onKeyDown={() => setForTeam(false)}
                    tabIndex={0}
                  >
                    {forMyselfLabel}
                  </div>
                  <div
                    role="button"
                    className={`${style.formButton} ${isForTeam(
                      true
                    )} corners py-1 text-center col-6 col-lg-5`}
                    onClick={() => setForTeam(true)}
                    onKeyDown={() => setForTeam(true)}
                    tabIndex={-1}
                  >
                    {forTeamLabel}
                  </div>
                </div>
              </div>
              {!forTeam && (
                <div className={`mb-5`}>
                  <div className={`mb-3 ming openSans-SemiBold`}>
                    {insuranceTypeLabel}
                  </div>
                  <div className={`col-12 px-0 flex gap-2 flex-wrap`}>
                    {insuranceTypes.map((type, index) => (
                      <div
                        key={index}
                        role="button"
                        tabIndex={-2 - index}
                        className={`${
                          isTypeSelected(type.value)
                          // quoteType.value === type.value ? style.selected : ""
                        } ${
                          style.formButton
                        } corners py-1 col-12 col-lg-5 text-center`}
                        onClick={() => setQuoteType(type)}
                        onKeyDown={() => setQuoteType(type)}
                      >
                        {type.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* {((quoteType && parseInt(quoteType.value) === 647) ||
                forTeam) && (
                <div className={``}>
                  <div className={`mb-3 ming openSans-SemiBold`}>
                    {whatToCoverLabel}
                  </div>
                  <div
                    className={`flex  gap-2 flex-wrap ${style.benefits} justify-content-lg-start justify-content-center`}
                  >
                    {benefits.map((ben, index) => (
                      <div
                        key={index}
                        className={`flex ${style.benefit} col-12 col-lg-4 align-items-center `}
                      >
                        <div
                          className={`${
                            lang === "zh-Hant" ? "col-4" : "col-7"
                          }  align-self-center openSans-SemiBold ming`}
                        >
                          {ben.name}
                        </div>
                        <div
                          role="button"
                          tabIndex={-20 - index}
                          className={`col-5 ${style.checkbox} corners d-grid`}
                          onClick={() => updateBenefits(ben.value)}
                          onKeyDown={() => updateBenefits(ben.value)}
                        >
                          {ben.selected ? (
                            <Icon
                              className={`ming`}
                              path={mdiCheckBold}
                              size={1}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className={`border-end me-5 ${style.divider}`}></div>
          <div className={`mb-5 col-lg-6`}>
            <div className={`col-lg-12`}>
              <h2 className={`ming mb-5`}>{aboutYouTitle}</h2>
              <div className={`flex`}>
                <div className={`col-12`}>
                  {forTeam && (
                    <div
                      className={` d-lg-flex d-block mb-4 align-items-center ${
                        formError.companyName ? style.errorField : ""
                      }`}
                    >
                      <div
                        className={`${style.label} col-lg-3 col-12 ming openSans-SemiBold`}
                      >
                        {companyNameLabel}
                      </div>
                      <input
                        type="text"
                        className={`corners border col-lg-8 col-12`}
                        value={quoteCompanyName}
                        onChange={e => setQuoteCompanyName(e.target.value)}
                      />
                    </div>
                  )}
                  <div
                    className={` d-lg-flex d-block mb-4 align-items-center ${
                      formError.name ? style.errorField : ""
                    }`}
                  >
                    <div
                      className={`${style.label} col-lg-3 col-12 ming openSans-SemiBold`}
                    >
                      {forTeam ? contactNameLabel : nameLabel}
                    </div>
                    <input
                      type="text"
                      className={`corners border col-lg-8 col-12`}
                      value={quoteName}
                      onChange={e => setQuoteName(e.target.value)}
                    />
                  </div>
                  <div
                    className={`${
                      formError.phone ? style.errorField : ""
                    } d-lg-flex d-block align-items-center align-items-center mb-4`}
                  >
                    <div
                      className={`${style.label}  col-lg-3 col-12 ming openSans-SemiBold`}
                    >
                      {phoneLabel}
                    </div>
                    <div className={`col-lg-8 col-12 flex`}>
                      <div className={`col-4 px-0 position-relative`}>
                        <DropdownV2
                          options={phoneCodeList}
                          selected={quoteCountryCode}
                          setSelected={setQuoteCountryCode}
                          selectValue={true}
                          className={`bg-ming`}
                        />
                      </div>
                      <input
                        type="number"
                        className={`corners border col-8`}
                        value={quotePhone}
                        onChange={e => setQuotePhone(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    className={`${
                      formError.email ? style.errorField : ""
                    } d-lg-flex d-block align-items-center`}
                  >
                    <div
                      className={`${style.label} col-lg-3 col-12 ming openSans-SemiBold`}
                    >
                      {emailLabel}
                    </div>
                    <input
                      type="email"
                      className={`corners border col-lg-8 col-12`}
                      value={quoteEmail}
                      onChange={e => setQuoteEmail(e.target.value)}
                    />
                  </div>

                  {errorMessage && (
                    <div className={`alert-red mt-4 openSans-Bold`}>
                      {errorMessage}
                    </div>
                  )}

                  <div
                    className={`${submitted ? "d-none" : ""} ${
                      errorMessage ? "mt-2" : "mt-5"
                    } flex justify-content-center`}
                  >
                    <Button
                      buttonId={`homepage_hero_submit`}
                      lang={lang}
                      type={`primary`}
                      text={submitLabel}
                      event={submitQuote}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h2 className={`ming`}>{thanksMsg}</h2>
      )}
    </div>
  )
}

export default QuotationForm
