// import "../components/forms/get_a_quote/get-a-quote.scss"

import React from "react"

import Layout from "../components/Layout"
import { getAllLocales, getCurrentLanguagePage } from "../plugins/common"
import Header from "../components/get-a-quote-v2/Header"
import QuotationForm from "../components/get-a-quote-v2/QuotationForm"

const GetQuotePageV2 = ({ pageContext, location }) => {
  // console.log("pageContext: ", pageContext)
  const { node, general, form } = pageContext

  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const aleaToken = pageContext.general.aleaToken
  const enableFooterForm = node.footerForm?.enableFooter
  const footerForm = {
    ...node.footerForm,
    ...form.freeQuote,
  }

  return (
    <Layout
      props={pageContext.general}
      meta={node.meta}
      localizations={getAllLocales(node.localizations, node.locale)}
      location={location}
      lang={lang}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <Header header={node.header} />
      <div className={`mx-lg-5`}>
        <QuotationForm
          formData={node.form}
          lang={lang}
          token={aleaToken}
          location={location}
        />
      </div>
    </Layout>
  )
}

export default GetQuotePageV2
